import React from "react"
import RacepageFS from "../components/racepageFS"

const MilanoSanremo2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Milano - Sanremo",
      edition: 2021,
      what: "Startlist",
      updated: "2021-03-19 21:44:28",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21799: {
        teamId: 21799,
        teamUciCode: "AST",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
        teamNationName: "Kazakhstan",
      },
      21800: {
        teamId: 21800,
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
        teamNationName: "Bahrain",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21811: {
        teamId: 21811,
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21821: {
        teamId: 21821,
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
        teamNationName: "Australia",
      },
      21822: {
        teamId: 21822,
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21829: {
        teamId: 21829,
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
        teamNationName: "Netherlands",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21846: {
        teamId: 21846,
        teamUciCode: "ANS",
        teamName: "Androni Giocattoli-Sidermec",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
      21851: {
        teamId: 21851,
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00e8",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21864: {
        teamId: 21864,
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21866: {
        teamId: 21866,
        teamUciCode: "TNN",
        teamName: "Team Novo Nordisk",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21867: {
        teamId: 21867,
        teamUciCode: "TDE",
        teamName: "Total Direct Energie",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
    },
    riders: {
      1: {
        id: 18258,
        startno: 1,
        firstName: "Wout",
        lastName: "Van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      2: {
        id: 27097,
        startno: 2,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      3: {
        id: 369,
        startno: 3,
        firstName: "Paul",
        lastName: "Martens",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1983-10-26",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      4: {
        id: 16988,
        startno: 4,
        firstName: "Sam",
        lastName: "Oomen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      5: {
        id: 2166,
        startno: 5,
        firstName: "Christoph",
        lastName: "Pfingsten",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1987-11-20",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      6: {
        id: 22133,
        startno: 6,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      7: {
        id: 729,
        startno: 7,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      11: {
        id: 150,
        startno: 11,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      12: {
        id: 37427,
        startno: 12,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      13: {
        id: 12800,
        startno: 13,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-03-05",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      14: {
        id: 22682,
        startno: 14,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      15: {
        id: 327,
        startno: 15,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      16: {
        id: 7580,
        startno: 16,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      17: {
        id: 22389,
        startno: 17,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      21: {
        id: 16672,
        startno: 21,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      22: {
        id: 28169,
        startno: 22,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      23: {
        id: 37430,
        startno: 23,
        firstName: "Senne",
        lastName: "Leysen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-03-18",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      24: {
        id: 10862,
        startno: 24,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      25: {
        id: 6266,
        startno: 25,
        firstName: "Petr",
        lastName: "Vakoc",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1992-07-11",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      26: {
        id: 20945,
        startno: 26,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      27: {
        id: 7964,
        startno: 27,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      31: {
        id: 40238,
        startno: 31,
        firstName: "Mattia",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-19",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      32: {
        id: 14804,
        startno: 32,
        firstName: "Luca",
        lastName: "Chirico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-07-16",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      33: {
        id: 102083,
        startno: 33,
        firstName: "Andrii",
        lastName: "Ponomar",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "2002-09-05",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      34: {
        id: 16605,
        startno: 34,
        firstName: "Josip",
        lastName: "Rumac",
        nationCode: "CRO",
        nationName: "Croatia",
        birthDate: "1994-10-26",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      35: {
        id: 33341,
        startno: 35,
        firstName: "Filippo",
        lastName: "Tagliani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-08-14",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      36: {
        id: 48505,
        startno: 36,
        firstName: "Nicola",
        lastName: "Venchiarutti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-07",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      37: {
        id: 32947,
        startno: 37,
        firstName: "Mattia",
        lastName: "Viel",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-22",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
      },
      41: {
        id: 27307,
        startno: 41,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      42: {
        id: 3096,
        startno: 42,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      43: {
        id: 1580,
        startno: 43,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      44: {
        id: 5836,
        startno: 44,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      45: {
        id: 872,
        startno: 45,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      46: {
        id: 14717,
        startno: 46,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      47: {
        id: 40783,
        startno: 47,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      51: {
        id: 2338,
        startno: 51,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      52: {
        id: 163,
        startno: 52,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      53: {
        id: 1006,
        startno: 53,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      54: {
        id: 120,
        startno: 54,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      55: {
        id: 16687,
        startno: 55,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      56: {
        id: 3050,
        startno: 56,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      57: {
        id: 63055,
        startno: 57,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      61: {
        id: 2312,
        startno: 61,
        firstName: "Enrico",
        lastName: "Battaglin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-17",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      62: {
        id: 33402,
        startno: 62,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      63: {
        id: 66439,
        startno: 63,
        firstName: "Filippo",
        lastName: "Zana",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-18",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      64: {
        id: 20386,
        startno: 64,
        firstName: "Umberto",
        lastName: "Marengo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-07-21",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      65: {
        id: 37757,
        startno: 65,
        firstName: "Daniel",
        lastName: "Savini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-09-26",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      66: {
        id: 20471,
        startno: 66,
        firstName: "Alessandro",
        lastName: "Tonelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-29",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      67: {
        id: 486,
        startno: 67,
        firstName: "Giovanni",
        lastName: "Visconti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1983-01-13",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      71: {
        id: 784,
        startno: 71,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      72: {
        id: 9746,
        startno: 72,
        firstName: "Pascal",
        lastName: "Ackermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-17",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      73: {
        id: 3224,
        startno: 73,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-03",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      74: {
        id: 361,
        startno: 74,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      75: {
        id: 92,
        startno: 75,
        firstName: "Marcus",
        lastName: "Burghardt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1983-06-30",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      76: {
        id: 777,
        startno: 76,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      77: {
        id: 16643,
        startno: 77,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      81: {
        id: 1310,
        startno: 81,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21807,
        teamName: "Cofidis",
      },
      82: {
        id: 14737,
        startno: 82,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21807,
        teamName: "Cofidis",
      },
      83: {
        id: 10990,
        startno: 83,
        firstName: "Guillaume",
        lastName: "Martin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-09",
        teamId: 21807,
        teamName: "Cofidis",
      },
      84: {
        id: 40385,
        startno: 84,
        firstName: "Attilio",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-18",
        teamId: 21807,
        teamName: "Cofidis",
      },
      85: {
        id: 9154,
        startno: 85,
        firstName: "Pierre-Luc",
        lastName: "P\u00e9richon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-01-04",
        teamId: 21807,
        teamName: "Cofidis",
      },
      86: {
        id: 122,
        startno: 86,
        firstName: "Fabio",
        lastName: "Sabatini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-02-18",
        teamId: 21807,
        teamName: "Cofidis",
      },
      87: {
        id: 4927,
        startno: 87,
        firstName: "Kenneth",
        lastName: "Vanbilsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-06-01",
        teamId: 21807,
        teamName: "Cofidis",
      },
      91: {
        id: 12474,
        startno: 91,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      92: {
        id: 27121,
        startno: 92,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      93: {
        id: 26504,
        startno: 93,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      94: {
        id: 3277,
        startno: 94,
        firstName: "Sam",
        lastName: "Bennett",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1990-10-16",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      95: {
        id: 1970,
        startno: 95,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      96: {
        id: 10971,
        startno: 96,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      97: {
        id: 4669,
        startno: 97,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      101: {
        id: 20584,
        startno: 101,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      102: {
        id: 46157,
        startno: 102,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      103: {
        id: 58639,
        startno: 103,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      104: {
        id: 6309,
        startno: 104,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      105: {
        id: 312,
        startno: 105,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      106: {
        id: 7695,
        startno: 106,
        firstName: "Magnus",
        lastName: "Cort",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-01-16",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      107: {
        id: 7910,
        startno: 107,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      111: {
        id: 2337,
        startno: 111,
        firstName: "Arnaud",
        lastName: "D\u00e9mare",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-08-26",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      112: {
        id: 37325,
        startno: 112,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      113: {
        id: 605,
        startno: 113,
        firstName: "Jacopo",
        lastName: "Guarnieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-14",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      114: {
        id: 341,
        startno: 114,
        firstName: "Ignatas",
        lastName: "Konovalovas",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1985-12-08",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      115: {
        id: 37397,
        startno: 115,
        firstName: "Cl\u00e9ment",
        lastName: "Davy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-07-17",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      116: {
        id: 21855,
        startno: 116,
        firstName: "Miles",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-01-18",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      117: {
        id: 4589,
        startno: 117,
        firstName: "Ramon",
        lastName: "Sinkeldam",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-02-09",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      121: {
        id: 2961,
        startno: 121,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      122: {
        id: 27099,
        startno: 122,
        firstName: "Filippo",
        lastName: "Ganna",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-07-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      123: {
        id: 65025,
        startno: 123,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      124: {
        id: 3929,
        startno: 124,
        firstName: "Salvatore",
        lastName: "Puccio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-31",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      125: {
        id: 2313,
        startno: 125,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      126: {
        id: 828,
        startno: 126,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      127: {
        id: 7724,
        startno: 127,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      131: {
        id: 3147,
        startno: 131,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      132: {
        id: 16581,
        startno: 132,
        firstName: "Aim\u00e9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      133: {
        id: 9349,
        startno: 133,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      134: {
        id: 27275,
        startno: 134,
        firstName: "Lorenzo",
        lastName: "Rota",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-05-23",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      135: {
        id: 10802,
        startno: 135,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      136: {
        id: 1267,
        startno: 136,
        firstName: "Pieter",
        lastName: "Vanspeybrouck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-02-10",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      137: {
        id: 9073,
        startno: 137,
        firstName: "Loic",
        lastName: "Vliegen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-12-20",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      141: {
        id: 875,
        startno: 141,
        firstName: "Matthias",
        lastName: "Br\u00e4ndle",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1989-12-07",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      142: {
        id: 780,
        startno: 142,
        firstName: "Davide",
        lastName: "Cimolai",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      143: {
        id: 3103,
        startno: 143,
        firstName: "Alessandro",
        lastName: "De Marchi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1986-05-19",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      144: {
        id: 32892,
        startno: 144,
        firstName: "Hugo",
        lastName: "Hofstetter",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-02-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      145: {
        id: 68377,
        startno: 145,
        firstName: "Guy",
        lastName: "Niv",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-03-08",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      146: {
        id: 16620,
        startno: 146,
        firstName: "James",
        lastName: "Piccoli",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-05",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      147: {
        id: 16963,
        startno: 147,
        firstName: "Guy",
        lastName: "Sagiv",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-12-05",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      151: {
        id: 174,
        startno: 151,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      152: {
        id: 9042,
        startno: 152,
        firstName: "Jasper",
        lastName: "De Buyst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-11-24",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      153: {
        id: 1013,
        startno: 153,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      154: {
        id: 16573,
        startno: 154,
        firstName: "Caleb",
        lastName: "Ewan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-07-11",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      155: {
        id: 16144,
        startno: 155,
        firstName: "Frederik",
        lastName: "Frison",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-07-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      156: {
        id: 840,
        startno: 156,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      157: {
        id: 6228,
        startno: 157,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      161: {
        id: 11828,
        startno: 161,
        firstName: "Ivan",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      162: {
        id: 38481,
        startno: 162,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      163: {
        id: 6409,
        startno: 163,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      164: {
        id: 1008,
        startno: 164,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      165: {
        id: 25690,
        startno: 165,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      166: {
        id: 7011,
        startno: 166,
        firstName: "Albert",
        lastName: "Torres",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-04-26",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      167: {
        id: 14126,
        startno: 167,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      171: {
        id: 2530,
        startno: 171,
        firstName: "Nacer",
        lastName: "Bouhanni",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-07-25",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      172: {
        id: 6104,
        startno: 172,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      173: {
        id: 19254,
        startno: 173,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      174: {
        id: 16154,
        startno: 174,
        firstName: "Daniel",
        lastName: "McLay",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-01-03",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      175: {
        id: 39293,
        startno: 175,
        firstName: "Thibault",
        lastName: "Guernalec",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-31",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      176: {
        id: 46959,
        startno: 176,
        firstName: "Cl\u00e9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      177: {
        id: 49662,
        startno: 177,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      181: {
        id: 2211,
        startno: 181,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      182: {
        id: 2327,
        startno: 182,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      183: {
        id: 2978,
        startno: 183,
        firstName: "Michael",
        lastName: "Hepburn",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-08-17",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      184: {
        id: 1715,
        startno: 184,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      185: {
        id: 39286,
        startno: 185,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      186: {
        id: 9234,
        startno: 186,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      187: {
        id: 44537,
        startno: 187,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      191: {
        id: 16610,
        startno: 191,
        firstName: "S\u00f8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21832,
        teamName: "Team DSM",
      },
      192: {
        id: 2275,
        startno: 192,
        firstName: "Romain",
        lastName: "Bardet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-11-09",
        teamId: 21832,
        teamName: "Team DSM",
      },
      193: {
        id: 14712,
        startno: 193,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21832,
        teamName: "Team DSM",
      },
      194: {
        id: 189,
        startno: 194,
        firstName: "Nicolas",
        lastName: "Roche",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1984-07-03",
        teamId: 21832,
        teamName: "Team DSM",
      },
      195: {
        id: 37219,
        startno: 195,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21832,
        teamName: "Team DSM",
      },
      196: {
        id: 6414,
        startno: 196,
        firstName: "Jasha",
        lastName: "S\u00fctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21832,
        teamName: "Team DSM",
      },
      197: {
        id: 7729,
        startno: 197,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21832,
        teamName: "Team DSM",
      },
      201: {
        id: 66104,
        startno: 201,
        firstName: "Sam",
        lastName: "Brand",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1991-02-27",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      202: {
        id: 47452,
        startno: 202,
        firstName: "Brian",
        lastName: "Kamstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-05",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      203: {
        id: 8869,
        startno: 203,
        firstName: "Peter",
        lastName: "Kusztor",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1984-12-27",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      204: {
        id: 6040,
        startno: 204,
        firstName: "David",
        lastName: "Lozano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1988-12-21",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      205: {
        id: 23358,
        startno: 205,
        firstName: "Andrea",
        lastName: "Peron",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-10-28",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      206: {
        id: 30361,
        startno: 206,
        firstName: "Charles",
        lastName: "Planet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-30",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      207: {
        id: 40419,
        startno: 207,
        firstName: "Umberto",
        lastName: "Poli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-08-27",
        teamId: 21866,
        teamName: "Team Novo Nordisk",
      },
      211: {
        id: 2982,
        startno: 211,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      212: {
        id: 19274,
        startno: 212,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      213: {
        id: 568,
        startno: 213,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      214: {
        id: 9894,
        startno: 214,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      215: {
        id: 4001,
        startno: 215,
        firstName: "Bert-Jan",
        lastName: "Lindeman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-06-16",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      216: {
        id: 16791,
        startno: 216,
        firstName: "Emil",
        lastName: "Vinjebo",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-24",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      217: {
        id: 6557,
        startno: 217,
        firstName: "Lukasz",
        lastName: "Wisniowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1991-12-07",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      221: {
        id: 16823,
        startno: 221,
        firstName: "Niccolo",
        lastName: "Bonifazio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      222: {
        id: 31,
        startno: 222,
        firstName: "Edvald",
        lastName: "Boasson Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      223: {
        id: 5811,
        startno: 223,
        firstName: "Lorrenzo",
        lastName: "Manzin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-07-26",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      224: {
        id: 3045,
        startno: 224,
        firstName: "Adrien",
        lastName: "Petit",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-09-26",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      225: {
        id: 964,
        startno: 225,
        firstName: "Julien",
        lastName: "Simon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-10-04",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      226: {
        id: 29,
        startno: 226,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      227: {
        id: 16637,
        startno: 227,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      231: {
        id: 133,
        startno: 231,
        firstName: "Vincenzo",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-11-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      232: {
        id: 37353,
        startno: 232,
        firstName: "Nicola",
        lastName: "Conci",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-01-05",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      233: {
        id: 20397,
        startno: 233,
        firstName: "Jacopo",
        lastName: "Mosca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-08-29",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      234: {
        id: 16646,
        startno: 234,
        firstName: "Ryan",
        lastName: "Mullen",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1994-08-07",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      235: {
        id: 95151,
        startno: 235,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      236: {
        id: 3930,
        startno: 236,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      237: {
        id: 5059,
        startno: 237,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      241: {
        id: 406,
        startno: 241,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      242: {
        id: 4953,
        startno: 242,
        firstName: "Sven Erik",
        lastName: "Bystr\u00f8m",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1992-01-21",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      243: {
        id: 45987,
        startno: 243,
        firstName: "Alessandro",
        lastName: "Covi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-28",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      244: {
        id: 11011,
        startno: 244,
        firstName: "Davide",
        lastName: "Formolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-10-25",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      245: {
        id: 18575,
        startno: 245,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      246: {
        id: 624,
        startno: 246,
        firstName: "Maximiliano",
        lastName: "Richeze",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1983-03-07",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      247: {
        id: 3933,
        startno: 247,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
    },
  }
  const race = "Milano Sanremo"
  const raceID = 4

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default MilanoSanremo2021
